import {memo, useEffect, useMemo, useState} from 'react';
import ExplanationTooltip from '../reusableElementsComponents/ExplanationTooltip';
import {useSelector} from 'react-redux';
import RecruitmentProcess from './RecruitmentProcess';
import {Select, Text, TextArea} from '../formComponents/Inputs';
import B2BApiService from "../../B2bApiService";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


function NewPositionGeneralEdit ({ data, formValidate, handleData }) {
    const contentfulData = useSelector(state => state.contentfulData.all['open positions']?.fields);

    let [jobIndustries, setJobIndustries] = useState([]);
    let [selectedJobProfiles, setSelectedJobProfiles] = useState([]);
    const [deadline, setDeadline] = useState(data.deadline);
    const [newPositionFormData, setNewPositionFormData] = useState({});
    const [selectedTargetProfile, setSelectedTargetProfile] = useState(data.target_profile ? data.target_profile : "");
    const [selectedDepartment, setSelectedDepartment] = useState(data.industry ? data.industry : "");
    const [recruitmentProcessData, _setRecruitmentProcess] = useState(data.recruitment_process);

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const setRecruitmentProcessData = (process) => {
        data['recruitment_process'] = process
        _setRecruitmentProcess(process);
        handleChange(process, 'recruitment_process')
    };

    const handleTitleChange = (e) => {
        data['title'] = e.target.value
        setNewPositionFormData({ ...newPositionFormData, 'title': e.target.value });
        handleChange(e.target.value, 'title')
    }

    const handleDescriptionChange = (e) => {
        data['description'] = e.target.value
        setNewPositionFormData({ ...newPositionFormData, 'description': e.target.value });
        handleChange(e.target.value, 'description');
    }

    const handleSelectTargetProfile = (e, id) => {

        selectedJobProfiles.map((pro)=> {
            if(pro.title === e.target.value) {
                var proId = pro.id
                if (e && proId) {
                    for (let i = 0; i < selectedJobProfiles.length; i++) {
                        if (selectedJobProfiles[i].title === e.target.value) {
                            let obj = {
                                id: selectedJobProfiles[i].id,
                                name: selectedJobProfiles[i].title
                            }
                            setSelectedTargetProfile(obj);
                            handleChange(selectedJobProfiles[i].id.toString(), 'target_profile_id')
                        }
                    }
                }
            }
            return true;
        })
    }

    const handleDateChange = (date) => {
        setDeadline(date);
        handleChange(date, 'deadline');
    }

    const handleChange = (value, key) => {
        data[key] = value;
        setNewPositionFormData({ ...newPositionFormData, [key]: value });
        handleData(data);
    }

    const handleSelectIndustry = (e, id) => {

        jobIndustries.map((industry)=> {
            if(industry.title === e.target.value) {
                var indId = industry.id
                if (e && indId) {
                    for (let i = 0; i < jobIndustries.length; i++) {
                        if (jobIndustries[i].title === e.target.value) {
                            b2bServiceObj.LoadTargetProfilesByIndustry(jobIndustries[i], setSelectedJobProfiles);
                            let obj = {
                                id: jobIndustries[i].id,
                                name: jobIndustries[i].title
                            }
                            setSelectedDepartment(obj);
                            handleChange(jobIndustries[i].id.toString(), 'industry_id')
                            return true;
                        }
                    }
                }
            }
            return true;
        })
    }

    useEffect(() => {
        if (!jobIndustries.length) {
            b2bServiceObj.LoadIndustriesList(setJobIndustries);
        }
        if (data && jobIndustries.length) {
            setSelectedTargetProfile(data.target_profile ? data.target_profile : "");
            setSelectedDepartment(data.industry ? data.industry : "");
            b2bServiceObj.LoadTargetProfilesByIndustry(data?.industry, setSelectedJobProfiles);
            setNewPositionFormData(data);
            _setRecruitmentProcess(data.recruitment_process)
            setDeadline(data.deadline);
        }
    }, [b2bServiceObj, setJobIndustries, setSelectedJobProfiles, jobIndustries.length, data]);

    return (
        <>
            <div className="titleSection section">
                <div className="title">
                    <div className="field-label">{contentfulData?.newPositionTitle}</div>
                    <Text
                        title=""
                        required={true}
                        fullWidth={true}
                        doValidation={formValidate}
                        errorContent={contentfulData?.positionTitleError}
                        handleChange={handleTitleChange}
                        value={newPositionFormData?.title}
                    />
                </div>
            {data.deadline &&
                            <div className="date">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="field-label">{contentfulData?.deadline}</div>
                                <DatePicker
                                    label=""
                                    value={deadline}
                                    onChange={date =>  handleDateChange(date)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        }
            </div>

                        {data?.industry?.id &&
                                    <div className="departmentSection section">
                                    <div className="department">
                                        <div className="field-label">{contentfulData?.department}</div>
                                        {jobIndustries.length > 0 &&
                                            <Select
                                                id={'industry_id'}
                                                label="Job Departments"
                                                options={jobIndustries}
                                                doValidation={formValidate}
                                                errorContent={"Please select department"}
                                                handleChange={handleSelectIndustry}
                                                option={selectedDepartment.name ? selectedDepartment.name : ""}
                                                defaultValue={"Select department"}
                                            />
                                        }
                                    </div>
                                    <div className="targetProfile">
                                        <div className="field-label">
                                            {contentfulData?.targetProfile}
                                            <ExplanationTooltip>
                                                <p>{contentfulData?.targetProfileExplanation}</p>
                                            </ExplanationTooltip>
                                        </div>
                                        {selectedDepartment && selectedJobProfiles.length > 0 &&
                                            <Select
                                            id={'target_profile'}
                                            label="Target Profiles"
                                            options={selectedJobProfiles}
                                            doValidation={formValidate}
                                            errorContent={"Please select target profile"}
                                            handleChange={handleSelectTargetProfile}
                                            option={selectedTargetProfile.name ? selectedTargetProfile.name : ""}
                                            defaultValue={"Select target profile"}
                                            />
                                        }
                                    </div>
                                </div>
                        }


            <div className="description">
                <div className="field-label">{contentfulData?.jobDescription} (<span className="italic"> {contentfulData?.visibleToCandidate}. </span>)</div>
                <TextArea
                    title=''
                    required={false}
                    fullWidth={true}
                    handleChange={handleDescriptionChange}
                    value={newPositionFormData.description}
                />
            </div>

            {<div className="recruitment-process section">
                <div className="field-label">{contentfulData?.hiringProcess} (<span className="italic"> {contentfulData?.visibleToCandidate}. </span>)</div>
                <RecruitmentProcess recruitmentProcess={recruitmentProcessData} onChangeFunc={setRecruitmentProcessData} />
            </div>}
        </>
    )
}

export default memo(NewPositionGeneralEdit)





