import React, { memo } from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from '../reusableElementsComponents/Button';
import Loading from '../reusableElementsComponents/Loading';
import EmailTemplatesEdit from './EmailTemplatesEdit';
import * as Constants from '../../constants'
import * as Utils from '../../Utilities'
import TabsMenu from "../reusableElementsComponents/TabsMenu";
import NewPositionGeneralEdit from '../../components/newOpenPositionComponents/NewPositionGeneralEdit'
import NewPositionAssessmentsEdit from '../../components/newOpenPositionComponents/NewPositionAssessmentsEdit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import B2BApi from '../../B2bApi';
import { ToastContainer, toast } from 'react-toastify';
import {useNavigate} from "react-router-dom";


function OpenPositionEdit ({ data, positionData, formValidate, onSave }) {
    let obj = new B2BApi();
    const navigate = useNavigate();

    let generalData = {
        title: positionData.title,
        deadline: positionData.deadline? new Date(positionData.deadline): null,
        description: positionData.description,
        recruitment_process: positionData.recruitment_process,
        industry: positionData.industry,
        target_profile: positionData.target_profile
    };

    let emailData = {
        send_assessment_email: positionData.send_assessment_email,
        qualify_email: positionData.qualify_email,
        reject_email: positionData.reject_email
    };

    let assessmentData = {
        assessments: positionData.assessments,
        email_list: positionData.email_list?.split(','),
        is_closed: positionData.is_closed
    };

    const payloads = positionData;

    const handleGeneralData = (val) => {
        payloads.title = val.title;
        payloads.description = val.description;
        payloads.deadline = val.deadline;
        payloads.industry_id = val.industry_id || payloads.industry.id;
        payloads.target_profile_id = val.target_profile_id || payloads.target_profile.id;
        payloads.recruitment_process = val.recruitment_process || payloads.recruitment_process;
    }

    const handleEmailData = (val) => {
        payloads.send_assessment_email = val.send_assessment_email;
        payloads.qualify_email = val.qualify_email;
        payloads.reject_email = val.reject_email;
    }

    const handleAssessmentData = (val) => {
        payloads.assessments = val.checkeAssesmentIds;
        payloads.is_closed = val.closedPool;
        payloads.email_list = val.emailList?.join(',');
    }

    let template = {
        tabs: [
            {
                component: <NewPositionGeneralEdit data={generalData} formValidate={formValidate} handleData={handleGeneralData} />,
                sectionTitle: 'general'
            },
            {
                component: <EmailTemplatesEdit data={emailData} handleData={handleEmailData} />,
                sectionTitle: 'email templates'
            },
            {
                component: <NewPositionAssessmentsEdit data={assessmentData} handleData={handleAssessmentData} />,
                sectionTitle: 'assessments'
            }
        ]
    }


    const textCopied = () => toast.success("Registration URL copied", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const copyJobLink = (hash) => {
        textCopied();
        navigator.clipboard.writeText(Constants.MAZHR_APP_URL + "?hash=" + hash + "#register");
    }
    const activeKey = (key) => {

    }

    const savePool = () => {
        const savePoolToast = toast.loading("Updating...");
        payloads.deadline = payloads.deadline.toString();
        let mydate = new Date(payloads.deadline);
        payloads.deadline = mydate.getFullYear() + "/" + (mydate.getMonth() + 1) + "/" + mydate.getDate() + " 00:00:00";
        let values = {
            title: payloads.title,
            description: payloads.description,
            deadline: payloads.deadline,
            industry_id: (payloads.industry_id && payloads.industry_id !== 'undefined') ?
                payloads.industry_id.toString() : payloads.industry.id.toString(),
            target_profile_id: (payloads.target_profile_id && payloads.target_profile_id !== 'undefined') ?
                payloads.target_profile_id.toString() : payloads.target_profile.id.toString(),
            send_assessment_email: payloads.send_assessment_email,
            qualify_email: payloads.qualify_email || '',
            email_list: payloads.emailList || payloads.email_list || '',
            assessments: payloads.checkeAssesmentIds || payloads.assessments,
            is_closed: payloads.closedPool || payloads.is_closed,
            reject_email: payloads.emailList || payloads.reject_email,
            hash: payloads.hash,
            recruitment_process: payloads.recruitment_process,
        }
        obj.apiCall('pool/', 'put', JSON.stringify(values)).then((response) => {
            if (response.ok && response.httpStatus === 200) {
                onSave();
                toast.update(savePoolToast, { render: "Edited successfully", type: "success", isLoading: false, autoClose: '2000', closeButton: true, hideProgressBar: true });
            }else{
                toast.update(savePoolToast, { render: "Unable to save edits", type: "error", isLoading: false, autoClose: '2000', closeButton: true, hideProgressBar: true });
            }
        }).catch((error) => {
            toast.update(savePoolToast, { render: "Unable to save edits", type: "error", isLoading: false, autoClose: '2000', closeButton: true, hideProgressBar: true });
        })
    }

    if (data) {
        return (
            <div className="open-positions-edit">
                <ToastContainer />
                <div className="basic-info section">
                    <div className="p-0 headers-btn d-flex">
                        <Button
                            btnstyle={'mazhrBtn light-green'}
                            onClick={()=>{
                                navigate('/talents/' + positionData.hash)
                                localStorage.setItem(Constants.LOCAL_STORAGE_KEY_SEARCH_TYPE, Constants.SEARCH_TYPE_TARGET_POSITION)
                            }}>
                            <VisibilityIcon></VisibilityIcon> &nbsp; View candidates
                        </Button>

                        &nbsp;
                        <Button btnstyle={'mazhrBtn light-green'} onClick={() => { copyJobLink(positionData.hash) }}>
                            <ContentCopyIcon></ContentCopyIcon> &nbsp; Copy job link
                        </Button>
                    </div>

                    {Utils.currentUserRole() !== Constants.USER_ROLE_TEAMLEAD && Utils.currentUserRole() !== Constants.USER_ROLE_SUPERVISOR && <div className="calls-to-action p-0">
                        <div className="buttons">
                            <Button btnstyle={'mazhrBtn default-green'} onClick={() => savePool()}>
                                <SaveIcon></SaveIcon> &nbsp; Save
                            </Button>
                        </div>
                    </div>}
                </div>
                <TabsMenu template={template} activeKey={activeKey}/>
            </div>
        )

    } else {
        return <Loading />
    }
}

export default memo(OpenPositionEdit);
