import {useEffect, useMemo, useRef, useState} from "react";
import TalentEducation from '../talentsComponents/TalentEducation';
import TalentMatchScore from './TalentMatchScore';
import TalentWorkHistory from './TalentWorkHistory';
import {Dialog} from 'primereact/dialog';
import { Checkbox } from "primereact/checkbox";
import tickIcon from '../../assets/images/icons/tickIcon.svg'
import loading from '../../assets/images/icons/loading.gif'
import { Box, TextField, MenuItem } from '@mui/material';
import TabsMenu from "../reusableElementsComponents/TabsMenu";
import Button from "../reusableElementsComponents/Button";
import MazhrModal from "../reusableElementsComponents/MazhrModal";
import {Select} from "../formComponents/Inputs";
import InputError from "../formComponents/InputError";
import B2BApiService from "../../B2bApiService";
import {useSelector} from "react-redux";
//import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import {PDFExport} from '@progress/kendo-react-pdf';
//import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Modal from '../reusableElementsComponents/Modal';

function TalentDetails({candidateId, industryId, hashParam, targetProfileId, hash, asEmployee, selectedJobs, candidate, pendingInvite,matchPercentage, deleteModalState ,setDeleteModalState, moveModalState, setMoveModalState, multipleIds, setPathCheck, pathCheck}) {
    const b2bServiceObj = useMemo(() => {
            return new B2BApiService();
        }
        , []);

    const contentfulData = useSelector(state => state.contentfulData.all['talent-data']?.fields);

    const talentDetailsRef = useRef(null)

    let [candidateData, setCandidateData] = useState(null);
    let [moveTalentValue,setMoveTalentValue] = useState("");
    const [loader, setLoader] = useState(false);
    const [updatedPools, setUpdatedPools] = useState();
    const [visible, setVisible] = useState(false);
    const [addPoolModal, setAddPoolModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const [confirmDisable, setConfirmDisable] = useState(true);
    const [errorMessage,setErrorMessage] = useState("")

    //Varaibles for invite to team
    let [teamsList, setTeamsList] = useState([]);
    let [candidateToHire, setCandidateToHire] = useState('');
    let [selectedTeam, setSelectedTeam] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    let [transferPoolOptions, setTransferPoolOptions] = useState();
    const [error, setError] = useState(false);

    //Variables for invite anonymous to pool
    let [poolLIst, setPoolList] = useState([]);
    let [selectedPool, setSelectedPool] = useState(null);
    let [candidateToInvite, setCandidateToInvite] = useState('');
    const [anonymousInviteModalShow, setAnonymousInviteModalShow] = useState(false);
    const [inviteToPoolError, setInviteToPoolError] = useState(false);
    const [allPools, setAllPools] = useState();
    const [fromPool, setFromPool] = useState(selectedJobs?.length === 1 ? selectedJobs[0] : "");
    const [fromPoolOptions, setFromPoolOptions] = useState([]);
    const [toPool, setToPool] = useState("");



    const [showMore, setShowMore] = useState(false);
    const [wordCount, setWordCount] = useState(25);


    const options = [
        { name: "Move To Another Pool", id : 1},
        { name: "Hire", id : 2},
        { name: "Delete From Pool", id : 3}]

    function getIconForTalentDropdown(index) {
        switch(index) {
            case 1: return <SwapHorizIcon style={{ color: '#FFAD0A' }}/>;
            case 2: return <PersonAddAlt1Icon style={{ color: '#0F9B8F' }}/>;
            case 3: return <PersonRemoveAlt1Icon style={{ color: '#9A326F' }}/>;
            default: return ""
        }
    }

    let text = candidateData?.summary


    useEffect(()=> {
        if(deleteModalState) {
            setVisible(true)
        }
    },[deleteModalState])
    useEffect(()=> {
        if(moveModalState) {
            setAddPoolModal(true)
        }
    },[moveModalState])

    useEffect(()=> {
        if(candidateData) {
            setFromPoolOptions(candidateData?.linked_pools)
        }
    },[candidateData])

    useEffect(()=> {
        setUpdatedPools(allPools?.filter(entry =>
            !fromPoolOptions?.some(item => item.title === entry.title)
          ))
    },[fromPoolOptions, allPools])

    useEffect(()=> {
        b2bServiceObj.LoadPoolsWithAllData(setAllPools,null)
    },[b2bServiceObj])

    useEffect(()=> {
        if(pathCheck) {
            setFromPool(selectedJobs[0])
        }
    // eslint-disable-next-line
    },[pathCheck])

    const handleShowMore = () => {
        setShowMore(!showMore);
        if (!showMore) {
            setWordCount(text.split(' ').length);
        } else {
            setWordCount(25);
        }
    };

    useEffect(()=> {
        setTransferPoolOptions(candidateData?.linked_pools)
    },[candidateData])

    const handleTalent = (e) => {
       if(e.target.value === "Move To Another Pool") {
            setMoveTalentValue("Move to Another Pool")
            setPathCheck(false)
            setAddPoolModal(true)
            setMoveModalState(true)
       }
       else if (e.target.value === "Hire") {
        setMoveTalentValue("Hire")
        if (teamsList.length === 0) {
            let list = []
            b2bServiceObj.LoadTeamsWithAllData((response) => {
                for (var i = 0; i < response.length; i++) {
                    list.push({"title": response[i].title, "hash": response[i].hash});
                }
                setTeamsList(list)
                setCandidateToHire(candidateData.info.email)
                setModalShow(true)
            })
        } else {
            setCandidateToHire(candidateData.info.email)
            setModalShow(true)
        }
       }
       else if (e.target.value === "Delete From Pool") {
        setPathCheck(false)
        setMoveTalentValue("Delete From the Pool")
            setVisible(true)
       }
    }

    const words = text?.split(' ').slice(0, wordCount);


    const template = {
        tabs: [
            {
                sectionName: 'score',
                component: <TalentMatchScore candidateData={candidateData} matchPercentage={matchPercentage}/>,
                sectionTitle: 'score'
            },
            {
                sectionName: 'education',
                component: <TalentEducation candidateData={candidateData}/>,
                sectionTitle: 'education'
            },
            {
                sectionName: 'work',
                component: <TalentWorkHistory candidateData={candidateData}/>,
                sectionTitle: 'work'
            }
        ]
    }
    // add logic to change to true if there is image
    let image = false;

    useEffect(() => {
        if (candidateId !== 0) {
            if (hash) {
                b2bServiceObj.GetUserByIdAndPoolHash(
                    candidateId,
                    hash,
                    asEmployee,
                    setCandidateData
                );
            } else if (industryId && targetProfileId) {
                b2bServiceObj.GetUserByIdIndustryAndTargetProfileId(
                    candidateId,
                    industryId,
                    targetProfileId,
                    asEmployee,
                    setCandidateData
                );
            } else {
                b2bServiceObj.GetUserById(candidateId, asEmployee, setCandidateData)
            }
        }

    }, [b2bServiceObj, asEmployee, candidateId, hash, targetProfileId, industryId, teamsList.length]);


    useEffect(() => {
        if (allPools && selectedJobs) {
            setUpdatedPools(allPools.filter((poolsObject) => {
            return !selectedJobs.includes(poolsObject.title);
          }))
        }
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [addPoolModal, selectedJobs]);


      useEffect(() => {
        // Function to recalculate and update the height of the target div
        const updateViewportDivHeight = () => {
          if (candidateData) {
            const viewportDiv = document.getElementById('viewportDiv');

            // Calculate the desired height
            const viewportHeight = window.innerHeight;
            const fixedValue = 220;
            const desiredHeight = viewportHeight - fixedValue;

            // Set the height of the target div
            viewportDiv.style.height = desiredHeight + 'px';
          }
        };

        // Initial height calculation
        updateViewportDivHeight();

        // Event listener for viewport height changes
        window.addEventListener('resize', updateViewportDivHeight);

        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', updateViewportDivHeight);
        };
      }, [candidateData]);


    const checkTeam = (e) => {
        if (e.target.value !== '') {
            let selectedTeam = teamsList.find(obj => obj.title === e.target.value);
            setSelectedTeam(selectedTeam);
        }
    }

    const assignTeam = () => {
        if (selectedTeam !== null && candidateToHire !== '') {
            b2bServiceObj.HireTalent(candidateToHire, selectedTeam.hash, (res) => {
                setCandidateToHire('');
                setSelectedTeam(null)
                setModalShow(false);
            })
        } else {
            setError(true);
        }
    }


    const deleteCandidate = () => {
        let user_id
        if(pathCheck) {
            user_id = multipleIds
            b2bServiceObj.DeleteCandidateFromTalentPool(
                {
                    user_id: user_id,
                    pool_hash: hashParam
                },
                setLoader,
                setVisible
            )
        }
        else {
            if (candidateId) {
                b2bServiceObj.DeleteCandidateFromTalentPool(
                    {
                        user_id: [candidateId],
                        pool_hash: hashParam
                    },
                    setLoader,
                    setVisible
                )
            }
        }


    }

    const closeModal = () => {
        setError(false);
        setModalShow(false);
        setMoveTalentValue("")
    }

    const checkPool = (e) => {
        if (e.target.value !== '') {
            let selectedPool = poolLIst.find(obj => obj.title === e.target.value);
            setSelectedPool(selectedPool);
        }
    }

    const assignPool = () => {
        if (selectedPool !== null && candidateToInvite !== '') {
            b2bServiceObj.InviteTalentToPool(candidateToInvite, true, selectedPool.hash, (res) => {
                setCandidateToInvite('');
                setSelectedPool(null)
                setAnonymousInviteModalShow(false);
            })
        } else {
            setInviteToPoolError(true);
        }
    }

    const closeAnonymousModal = () => {
        setInviteToPoolError(false);
        setAnonymousInviteModalShow(false);
    }
    const activeKey = (key) => {
    }

    const onHired = () => {
        if (teamsList.length === 0) {
            let list = []
            b2bServiceObj.LoadTeamsWithAllData((response) => {
                for (var i = 0; i < response.length; i++) {
                    list.push({"title": response[i].title, "hash": response[i].hash});
                }
                setTeamsList(list)
                setCandidateToHire(candidateData.info.email)
                setModalShow(true)
            })
        } else {
            setCandidateToHire(candidateData.info.email)
            setModalShow(true)
        }
    }
    useEffect(() => {
       if (toPool && fromPool) {
        if (toPool === fromPool) {
            setConfirmDisable(true)
            // setErrorMessage("! User cannot be moved between same pool")
        }
        else {
            setConfirmDisable(false)
            setErrorMessage("")
        }
       }
       else {
        setConfirmDisable(true)
       }
    },[toPool,fromPool])

    const pageReloader = () => {
        setTimeout(()=> {
            window.location.reload()
        },1000)
    }

    const deleteCandidateModal = () => {
        setVisible(false)
        setDeleteModalState(false)
        setMoveTalentValue("")
        setLoader(false);
    }

    const transferPool = () => {
        let origin_hash;
        let destination_hash;
        let user_id;
        let remove_from_origin;
        allPools.map((obj) => {
          if (obj.title === fromPool) {
            origin_hash = obj.hash;
          } else if (obj.title === toPool) {
            destination_hash = obj.hash;
          }
          return true
        });
        if(pathCheck) {
            if(multipleIds.length > 1) {
                user_id = multipleIds
            }
            else {
                user_id = [multipleIds]
            }
        }
        else {
            user_id = [candidateData?.info?.id]
        }
        remove_from_origin = checked

        // Use the origin_hash and destination_hash variables as needed
        b2bServiceObj.TransferPoolFromOrigin(origin_hash, destination_hash, user_id, remove_from_origin, setLoader, pageReloader, setAddPoolModal, setErrorMessage);
      };


    const fromSelection = (e) => {
        setFromPool(e.target.value)
    }

    const toSelection = (e) => {
        setToPool(e.target.value)
    }

    const hidingModal = () => {
    setLoader(false)
    setAddPoolModal(false)
    setMoveModalState(false)
    setMoveTalentValue("")
    setErrorMessage("")
    }
    /*const downloadAsPdf = () => {
        let pdfFileName = "profile.pdf"
        if (candidateData.info.first && candidateData.info.last) {
            pdfFileName = candidateData.info.first + "-" + candidateData.info.last + "-" + pdfFileName
        }
        const excludeComponents = document.querySelectorAll('.exclude-from-pdf');
        /!*const element = talentDetailsRef.current;*!/
        let options = talentDetailsRef.current.getOptions();
        options.fileName = pdfFileName;

        // hide the components you want to exclude from the PDF
        excludeComponents.forEach((component) => {
            component.style.display = 'none';
        });

        // add a delay to ensure that the components are fully hidden
        setTimeout(() => {
            //html2pdf().from(element).set(opt).save();
            talentDetailsRef.current.save(options);

            // show the hidden components after the PDF is generated
            excludeComponents.forEach((component) => {
                component.style.display = 'block';
            });
        }, 300); // adjust the delay time as needed
    };*/


    return (
        <>
            {pendingInvite &&
            <div className="talent-details" id="viewportDiv">
                <div className="invitation-acceptance-div">
                <p className="invitation-acceptance-text">
                    Waiting for candidate to accept invite
                </p>
                </div>

            </div>}

            {candidateData && !pendingInvite &&
            <PDFExport
                ref={talentDetailsRef}
                margin={0}
                imageType="jpeg"
                imageQuality={0.98}
                scale={2}
                pdfOptions={{unit: 'in', format: 'a4', orientation: 'portrait', height: 15}}
            >
                <div className="talent-details" id="viewportDiv">
                    <Modal visible={visible} onHide={()=> setVisible(false)}
                        title={'Delete Candidate'}
                        text={`Are you sure you want to
                        Delete ${candidateData?.info?.first} ${candidateData?.info?.last} from this Pool? This action canot be undone.`}
                        buttonLabel='Delete'
                        loader={loader}
                        actionButton={() => deleteCandidate()}
                        cancel={()=> deleteCandidateModal()} />

                    <Dialog
                        showHeader={false}
                        // header={`Delete ${candidateData?.info?.first} ${candidateData?.info?.last}`}
                        visible={addPoolModal}
                        style={{width: '575px', textAlign: "center"}}
                        onHide={() => setAddPoolModal(false)}
                        >
                            <br/>
                            <img src={tickIcon} alt="warning sign"></img>
                            <br/>
                            <br/>
                        {pathCheck === true? (<><h5 className="black-text">Move <span className="default-green-span">Selected Candidates</span> to Another Pool</h5></>):(<>
                            <h5 className="black-text">Move <span className="default-green-span">{`${candidateData?.info?.first} ${candidateData?.info?.last}`}</span> to Another Pool</h5></>)}
                            <br/>
                        <div className="flex-centered-div">
                        <Select
                        filter={true}
                        label="From"
                        style={{width: "250px", height: "2.4rem", fontSize: "14px"}}
                        options={transferPoolOptions}
                        option={fromPool}
                        value={fromPool}
                        disabled={pathCheck}
                        handleChange={e => fromSelection(e)}
                    />
                        <Select
                        filter={true}
                        label="To"
                        style={{width: "250px", height: "2.4rem", fontSize: "14px"}}
                        options={updatedPools}
                        value={toPool}
                        handleChange={e => toSelection(e)}
                    />
                        </div>
                        <br/>
                       <div>
                        <div className="checkbox-div">
                        <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
                       <label htmlFor="deleteFromPool" className="ml-5" >Remove from Origin Pool</label>
                        </div>
                       </div>
                       <br/>
                       {setErrorMessage.length &&
                       <p className="warning-text">{errorMessage}</p>
                       }

                        <div className="d-flex justify-content-space-evenly my-4 w-100">
                            <Button btnstyle={'mazhrBtn default-white-border w-20'}
                                    onClick={() => (hidingModal()) }>Cancel</Button>
                            <Button btnstyle={'mazhrBtn default-green-200 m-10 right w-20'}
                                onClick={transferPool}
                                disabled={confirmDisable}
                            >
                                                       {loader ?
                            <div>
                                <img width="30" height="30" src={loading} alt="Loading..."/>
                            </div>

                            :
                            <div>
                                 <span>Confirm</span>
                            </div>

                        }

                        </Button>
                        </div>
                    </Dialog>

                    <div>

                        <div className="borderedBox">
                            {!candidateData.summary && (!asEmployee && !candidateData.info.is_employee && !candidateData.info.is_anonymous) &&
                            <div className="flex-mar-bottom">

                            <Box width='250px' sx={{ fontFamily: 'Raleway' }}>
                            <TextField
                                label="Actions For Talent"
                                value={moveTalentValue}
                                select={true}
                                onChange={e => handleTalent(e)}
                                fullWidth
                                size='small'
                                color='primary'

                                inputProps={{ style: { fontSize: '14px' } }} // font size of input text
                                InputLabelProps={{ style: { fontSize: '14px' } }} // font size of input label
                            >
                                {options &&
                                    options.map((obj, index) => {
                                        return <MenuItem key={index} value={obj.name}>
                                            {getIconForTalentDropdown(obj.id)}
                                            &nbsp; &nbsp;
                                            {obj.name}
                                        </MenuItem>
                                    })
                                }
                            </TextField>
                        </Box>
                            </div>

                            }
                            <div className="basic-info">
                                <div className="image me-4">
                                    {image ?
                                        <img className='user-image' src="" alt=""/>
                                        :
                                        matchPercentage !== undefined &&  <div
                                            className={"image-replacement mb-3 " + (matchPercentage >= 70 ? 'green' : matchPercentage >= 40 ? 'yellow' : 'purple')}>
                                            <h1 className='initials'>
                                                {candidateData.info.first.length > 0 && candidateData.info.first[0]}{candidateData.info.last.length > 0 && candidateData.info.last[0]}
                                            </h1>
                                        </div>
                                    }
                                    {asEmployee}
                                    {candidateData.is_employee}
                                    {candidateData.summary && (!asEmployee && !candidateData.info.is_employee && !candidateData.info.is_anonymous) &&
                                    <Button
                                        btnstyle={'mazhrBtn default-green white w-100 exclude-from-pdf'}
                                        onClick={() => onHired()}>
                                        <HowToRegIcon></HowToRegIcon>&nbsp; &nbsp;{contentfulData?.buttonsText.hired}
                                    </Button>}
                                    {candidateData.summary && (!asEmployee && candidateData.info.is_anonymous) &&
                                    <Button btnstyle={'mazhrBtn default-green white w-100 exclude-from-pdf'}
                                            onClick={() => {
                                                if (poolLIst.length === 0) {
                                                    let list = []
                                                    b2bServiceObj.LoadPoolsWithAllData((response) => {
                                                        for (var i = 0; i < response.length; i++) {
                                                            list.push({
                                                                "title": response[i].title,
                                                                "hash": response[i].hash
                                                            });
                                                        }
                                                        setPoolList(list)
                                                        setCandidateToInvite(candidateData.info.id)
                                                        setAnonymousInviteModalShow(true)
                                                    })
                                                } else {
                                                    setCandidateToInvite(candidateData.info.id)
                                                    setAnonymousInviteModalShow(true)
                                                }
                                            }
                                            }>{contentfulData?.buttonsText.invite_to_pool}</Button>}
                                </div>
                                <div className="description d-flex flex-column">
                                    <div className='match-heading'>
                                        <div className="title">
                                            <h4 className="name m-0">{candidateData.info.first} {candidateData.info.last}</h4>
                                            {(matchPercentage!== undefined && matchPercentage!== 0) ?
                                                <p className={"match l bold m-10 left " + (matchPercentage>= 70 ? 'green' : matchPercentage>= 40 ? 'yellow' : 'purple')}>{matchPercentage + '% match'}</p>
                                                :
                                                null
                                            }
                                            {/*<div>
                                                <button className="exclude-from-pdf"
                                                        style={{border: "none", backgroundColor: "transparent"}}
                                                        onClick={downloadAsPdf}><FileDownloadIcon></FileDownloadIcon>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </div>
                                    <div>{candidateData.headline &&
                                    <h6 className="short-description m">{candidateData.headline}</h6>}

                                        {candidateData.summary &&
                                        <p className="long-description s">{words.join(' ')} {text.split(' ').length > 25 && (
                                            <button onClick={handleShowMore} className="show-more-link link">
                                                {showMore ? 'Show Less' : 'Show More'}
                                            </button>
                                        )}</p>}
                                    </div>

                                </div>
                            </div>
                        </div>
                        {!candidateData.summary && (!asEmployee && candidateData.info.is_anonymous) &&
                        <Button btnstyle={'mazhrBtn default-green white exclude-from-pdf'}
                                onClick={() => {
                                    if (poolLIst.length === 0) {
                                        let list = []
                                        b2bServiceObj.LoadPoolsWithAllData((response) => {
                                            for (var i = 0; i < response.length; i++) {
                                                list.push({"title": response[i].title, "hash": response[i].hash});
                                            }
                                            setPoolList(list)
                                            setCandidateToInvite(candidateData.info.id)
                                            setAnonymousInviteModalShow(true)
                                        })
                                    } else {
                                        setCandidateToInvite(candidateData.info.id)
                                        setAnonymousInviteModalShow(true)
                                    }
                                }
                                }>{contentfulData?.buttonsText.invite_to_pool}</Button>}
                        <MazhrModal
                            show={modalShow}
                            onHide={closeModal}
                            header={<h5 className="w-100 my-3 text-center">Are you sure you want to hire this
                                person?</h5>}
                        >
                            <div className="d-flex flex-column align-items-center mar-20">
                                <div className="buttons-wrapper">
                                    <div className="add-to-team">
                                        <p className="text-center">Add candidate to the team</p>
                                        <Select
                                            id={'team'}
                                            label='Teams'
                                            options={teamsList}
                                            handleChange={checkTeam}
                                            defaultValue={"Select team"}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center my-4 w-100">
                                    <Button btnstyle={'mazhrBtn default-green m-10 right w-20'}
                                            onClick={assignTeam}>Add</Button>
                                    <Button btnstyle={'mazhrBtn default-pink white w-20'}
                                            onClick={closeModal}>Cancel</Button>
                                </div>
                                {error && <InputError errorMessage='Please assign candidate to the team'/>}
                            </div>
                        </MazhrModal>
                        <MazhrModal
                            show={anonymousInviteModalShow}
                            onHide={closeAnonymousModal}
                            header={<h5 className="w-100 my-3 text-center">Are you sure you want to invite this
                                person?</h5>}
                        >
                            <div className="d-flex flex-column align-items-center mar-20">
                                <div className="buttons-wrapper">
                                    <div className="add-to-team">
                                        <p className="text-center">Invite candidate for position</p>
                                        <Select
                                            id={'pool'}
                                            label='Job Positions'
                                            options={poolLIst}
                                            handleChange={checkPool}
                                            defaultValue={"Select sob position"}
                                            //option={selectedIndustry.title}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center my-4 w-100">
                                    <Button btnstyle={'mazhrBtn default-green m-10 right w-20'}
                                            onClick={assignPool}>Add</Button>
                                    <Button btnstyle={'mazhrBtn default-pink w-20'}
                                            onClick={closeAnonymousModal}>Cancel</Button>
                                </div>
                                {inviteToPoolError && <InputError errorMessage='Please assign candidate to the team'/>}
                            </div>
                        </MazhrModal>
                    </div>
                    <TabsMenu template={template} activeKey={activeKey}/>
                </div>
            </PDFExport>
            }
        </>
    )
}

export default TalentDetails;






