import { useState, useRef, useEffect } from 'react';
import PageLayout from "../../components/layoutComponents/PageLayout";
import Button from "../../components/reusableElementsComponents/Button";
import listIcon from "../../assets/images/icons/list.svg";
import TabsMenu from "../../components/reusableElementsComponents/TabsMenu";
import KeyAnalytics from "../../components/Analytics/keyAnalytics/KeyAnalytics";
//import BehaviourStrengths from '../../components/Analytics/behaviourStrengths/behaviourStrengths';
import CompetencePoints from '../../components/Analytics/competencePoints/competencePoints';
import FileDownload from './FileDownload';
import MotivationStrengths from '../../components/Analytics/motivationStrengths/motivationStrengths';
import SalesReport from "../../components/Analytics/salesReportComponent/salesReport";
import MazhrModal from "../../components/reusableElementsComponents/MazhrModal";
import {Checkbox} from '../../components/reusableElementsComponents/Checkbox';
import TeamStrengths from "../../components/Analytics/teamStrengths/teamStrengths";
import B2BApi from '../../B2bApi';
import { fetchContentfulAnalyticsData } from '../../store/actions/contentfulAnalyticsDataActions';
import { useDispatch } from 'react-redux';

function TeamAnalytics() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [downloadData, setDownloadData] = useState();
  const [columnLabels, setColumnLables] = useState();
  const [tabActiveKey, setTabActiveKey] = useState("0");
  let [teams, setTeams] = useState([]);

  const firstInputRef = useRef();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchContentfulAnalyticsData());
  }, [dispatch]);

  useEffect(() => {
    const obj = new B2BApi();
    obj.apiCall('team/', 'get').then((res) => {
      res = res.json?.data;
      if (res) {
        let newTeams = [];
        for (let index = 0; index < res.length; index++) {
          newTeams.push({ id: res[index]?.hash, name: res[index]?.title });
        }
        setTeams([...newTeams]);
      }
    })
  }, [])
  const activeKey = (key) => {
    setTabActiveKey(key)
  }

 const columnLabelUpdation = (data) => {
  if(data) {
    setColumnLables(data)
  }
 }

 const downloadDataUpdation = (data) => {
  if(data) {
    setDownloadData(data)
  }
 }

  useEffect(()=> {
    setDownloadData([])
    setColumnLables([])
    activeKey(tabActiveKey)
  },[tabActiveKey])

  const template = {
    tabs: [
      {
        component: <KeyAnalytics selectedTeams={selectedTeams} tabActiveKey={tabActiveKey} setTabActiveKey={setTabActiveKey} activeKey={activeKey} />,
        sectionTitle: 'KEY ANALYTICS'
      },
      {
        component: <CompetencePoints selectedTeams={selectedTeams} columnLabelUpdation={columnLabelUpdation} downloadDataUpdation={downloadDataUpdation} tabActiveKey={tabActiveKey} />,
        sectionTitle: 'COMPETENCIES'
      },
      {
        component: <MotivationStrengths selectedTeams={selectedTeams} columnLabelUpdation={columnLabelUpdation} downloadDataUpdation={downloadDataUpdation} tabActiveKey={tabActiveKey} setTabActiveKey={setTabActiveKey} activeKey={activeKey}/>,
        sectionTitle: 'MOTIVATIONS'
      },
      {
        component: <SalesReport selectedTeams={selectedTeams} downloadDataUpdation={downloadDataUpdation} columnLabelUpdation={columnLabelUpdation}  tabActiveKey={tabActiveKey} />,
        sectionTitle: 'SALES STRENGTHS'
      },
      {
        component: <TeamStrengths selectedTeams={selectedTeams} columnLabelUpdation={columnLabelUpdation} downloadDataUpdation={downloadDataUpdation}  tabActiveKey={tabActiveKey} />,
        sectionTitle: 'TEAM STRENGTHS'
      },
      /*{
        component: <BehaviourStrengths selectedTeams={selectedTeams} tabActiveKey={tabActiveKey} />,
        sectionTitle: 'BEHAVIOURS'
      },*/

    ]
  }



  const toggleModalOpenState = () => {
    setModalIsOpen(state => !state);
  };

  const handleOnChange = e => {
    const { name, checked, value } = e.target;

    setSelectedItems(teams => ({
      ...teams,
      [name]: checked
    }));

    if (checked) {
      selectedTeams.push({ name: name, hash: value });
    } else {
      const index = selectedTeams.findIndex(item => item.hash === value);
      if (index !== -1) {
        selectedTeams.splice(index, 1);
      }
    }
    setSelectedTeams([...selectedTeams])
  };

  const handleSubmit = () => {
    JSON.stringify(
      Object.keys(selectedItems).reduce((teams, key) => {
        if (selectedItems[key]) {
          return [...teams, key];
        }
        return teams;
      }, [])
    )
    toggleModalOpenState();
  }

  const handleClick = (e, team) => {
    const deletionObject = selectedTeams.find((obj) => obj.hash === team.hash)
    const { name } = deletionObject
    const deletionObjectIndex = selectedTeams.findIndex(obj => obj.hash === team.hash)
    selectedTeams.splice(deletionObjectIndex, 1)
    setSelectedTeams([...selectedTeams])

    const updatedDisplayTeams = displayTeams.map((obj) => {
      if (obj.key === deletionObject.hash) {
        return {
          ...obj,
          props: {
            ...obj.props,
            children: [
              {
                ...obj.props.children[0],
                props: {
                  ...obj.props.children[0].props,
                  isChecked: false
                }
              },
              ...obj.props.children.slice(1)
            ]
          }
        }
      }
      return obj
    })

    displayTeams = updatedDisplayTeams
    setSelectedItems({
      ...selectedItems,
      [name]: false
    })
  }

  var displayTeams = teams && teams.map((team, index) => (
    <div key={team.id} className="team-card">
      <Checkbox
        isChecked={selectedItems[team.name] || false}
        onChange={handleOnChange}
        name={team.name}
        value={team?.id}
        innerRef={index === 0 ? firstInputRef : null}
      />
      <p className='m-10 left'>{team.name}</p>
    </div>
  ))



  return (
    <PageLayout>
      <div className="full-width p-20 desktop" style={{display: "flex", justifyContent: "space-between"}}>
        <Button id="select-team-btn" btnstyle={'mazhrBtn default-green upper'} onClick={toggleModalOpenState}>
          <img style={{ width: '15px', height: '15px' }} className="m-10 right" src={listIcon} alt="list" />
          All teams
        </Button>
       <FileDownload tabActiveKey = {tabActiveKey} columnLabels={columnLabels} downloadData={downloadData} selectedTeams={selectedTeams}></FileDownload>
        <MazhrModal show={modalIsOpen} onHide={toggleModalOpenState}>
          <div className="team-card-wrapper">
            {displayTeams}
          </div>
          <Button btnstyle={'mazhrBtn default-green upper mar-25 top'} onClick={handleSubmit}>Select teams</Button>
        </MazhrModal>
        {selectedTeams.length > 0 &&
          <div style={{position: "relative", right: "350px", display: "flex"}}>
            {selectedTeams.map((team, index) => {
              return (
                <Button btnstyle={'mazhrBtn light-green m-10 right upper'} key={index}>
                  <p className="regular m-0">{team.name}</p>
                  <img onClick={e => handleClick(e, team)} src="https://img.icons8.com/material-rounded/24/null/delete-sign.png" style={{ height: "18px", marginLeft: "10px", opacity: "0.5" }}  alt=""/>
                </Button>
              )
            })}
          </div>
        }
      </div>

      <div className="full-width p-20 mobile">
        <Button id="select-team-btn" btnstyle={'mazhrBtn default-green upper'} onClick={toggleModalOpenState}>
          <img style={{ width: '15px', height: '15px' }} className="m-10 right" src={listIcon} alt="list" />
          All teams
        </Button>
        <MazhrModal show={modalIsOpen} onHide={toggleModalOpenState}>
          <div className="team-card-wrapper">
            {displayTeams}
          </div>
          <Button btnstyle={'mazhrBtn default-green upper mar-25 top'} onClick={handleSubmit}>Select teams</Button>
        </MazhrModal>
        {selectedTeams.length > 0 &&
          <div className="selected-teams-wrapper flex mar-15 top">
            {selectedTeams.map((team, index) => {
              return (
                <Button btnstyle={'mazhrBtn light-green m-10 right upper'} key={index}>
                  <p className="regular m-0">{team?.name}</p>
                </Button>
              )
            })}
          </div>
        }
      </div>

      <div className="tabs-wrapper background-green tabs-styling">
        <TabsMenu template={template} activeKey={activeKey} tabActiveKey={tabActiveKey} />
      </div>
    </PageLayout>
  )
}

export default TeamAnalytics
