export const CONTENTFUL_API_BASE_URL=process.env.REACT_APP_CONTENTFUL_CDN
export const CONTENTFUL_API_SPACE_ID=process.env.REACT_APP_CONTENTFUL_API_SPACE
export const CONTENTFUL_API_TOKEN=process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN

export const HUBSPOT_FORMS_JS_URL=process.env.REACT_APP_HUBSPOT_FORMS_JS_URL
export const HUBSPOT_PORTAL_ID=process.env.REACT_APP_HUBSPOT_PORTAL_ID
export const HUBSPOT_CONTACT_USE_FORM_ID=process.env.REACT_APP_HUBSPOT_CONTACT_USE_FORM_ID

export const API_URL = process.env.REACT_APP_API_URL;
export const TERMS_CONDITIONS_URL = process.env.REACT_APP_TERMS_CONDITIONS_URL;
export const MAZHR_APP_URL = process.env.REACT_APP_MAZHR_APP_URL;

export const IMAGES_BASE_URL = process.env.REACT_APP_IMAGES_URL;


export const MAZHR_JWT_KEY = 'mazhr_jwt';
export const MAZHR_USER_DATA_KEY = 'mazhr_user_data_key';

export const MAZHR_REG_DATA = 'mazhr_reg_data'

export const SEARCH_TYPE_SUITABILITY = 'suitability'
export const SEARCH_TYPE_TARGET_POSITION = 'target-position'

// eslint-disable-next-line
export const VALID_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line
export const VALID_PASSWORD = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;

// eslint-disable-next-line
export const VALID_NAME = /^[\p{L}\p{M}\d@$#%^&*()\-+=[\]{};:'",.<>?/~|\\ ]{2,}$/u;

// eslint-disable-next-line
export const VALID_PHONE = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const EXAMPLE_RECRUITMENT_PROCESS = [
    {
        title: 'Phone interview',
        description: 'Phone call to evaluate a candidate \'s fit for the job'
    },
    {
        title: 'Applicant assessment',
        description: 'Hiring process includes work-related assessments. It takes 15-20min to complete one assessment. Assessments are provided by AON.'
    },
    {
        title: 'Technical interview',
        description: 'Interview to assess your technical ability for the role, and the depth and breadth of your knowledge in your chosen field'
    }
]

export const FAQCATEGORIES = ['faq-service-use', 'faq-analytics']

export const TALENT_SEARCH_FILTER_TEAM_STRENGTHS = "Team Strengths"
export const TALENT_SEARCH_FILTER_SALES_STRENGTHS = "Sales Strengths"
export const TALENT_SEARCH_FILTER_MOTIVATION_FACTORS = "Motivation Factors"
export const TALENT_SEARCH_FILTER_COMPETENCES = "Competences"
export const ENTER_KEY = "Enter"

export const USER_FILTER_TYPE_ALL = 2
export const USER_FILTER_TYPE_ONLY_POOLS = 1
export const USER_FILTER_TYPE_ONLY_TEAMS = 0

export const INITIAL_TALENT_SEARCH_FILTER_ARRAY = [
    {"Team Strengths": {}},
    {"Sales Strengths": {}},
    {"Motivation Factors": {}},
    {"Competences": {}}
]

//Used on talent search page
export const LOCAL_STORAGE_KEY_SELECTED_MENU = "selected-menu"
export const LOCAL_STORAGE_KEY_SEARCH_TYPE = "search-type"
export const LOCAL_STORAGE_KEY_SELECTED_TARGET_PROFILE = "selected-target-profile"
export const LOCAL_STORAGE_KEY_SELECTED_INDUSTRY = "selected-industry"
export const LOCAL_STORAGE_KEY_SELECTED_CANDIDATE_ID = "selected-candidate-id"
export const LOCAL_STORAGE_KEY_SELECTED_POSITION_ID = "selected-position-id"
export const LOCAL_STORAGE_KEY_USER_ROLE_ID = "user-role-id"

export const URL_WITH_HASH_FOR_TEAM_CHECK = "#internal"

export const DOWNLOAD_OPTIONS_FOR_ALL = "0"

export const TAB_KEY_ANALYTICS_KEY = "0"
export const TAB_COMPETENCIES_KEY = "1"
export const TAB_MOTIVATIONS_KEY = "2"
export const TAB_SALES_STRENGTHS_KEY = "3"
export const TAB_TEAM_STRENGTHS_KEY = "4"

export const USER_ROLE_ADMIN = 1
export const USER_ROLE_HRD = 2
export const USER_ROLE_TEAMLEAD = 3
export const USER_ROLE_SUPERVISOR = 4

export const ALLOWED_USER_ROLES_COMPANIES = [1,3,5,55,133,146,152,154]

export function getUserRoleStringByRoleId(roleId) {
    switch (roleId) {
        case USER_ROLE_ADMIN:
            return "Admin";
        case USER_ROLE_HRD:
            return "HRD";
        case USER_ROLE_TEAMLEAD:
            return "Team Lead";
        case USER_ROLE_SUPERVISOR:
            return "Supervisor";
        default:
            return "Unknown"; // Return null for unknown role IDs
    }
}


