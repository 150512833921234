import React, {useState, useEffect, useMemo} from "react";
import StrengthsRadarChart from "../reusableElementsComponents/StrengthsRadarChart";
import ProgressBar from 'react-bootstrap/ProgressBar';
import TargetPositionComposition from "./TargetPositionComposition";
import CandidateCompetences from "./CandidateCompetences";
import CandidateMotivationFactors from "./CandidateMotivationFactors";
import ScoreLine from "../reusableElementsComponents/ScoreLine";
import ExplanationTooltip from "../reusableElementsComponents/ExplanationTooltip";
import {Accordion} from "react-bootstrap";
import 'react-circular-progressbar/dist/styles.css';
import {useSelector} from 'react-redux';
import B2BApiService from "../../B2bApiService";
import {toast, ToastContainer} from "react-toastify";


function TalentMatchScore({candidateData, matchPercentage}) {
    const data = candidateData;

    const copy = useSelector(state => state.contentfulData.all['talent-data']?.fields);

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }, []);

    const sendAssessmentReminder = () => {
        const updateTeamToast = toast.loading(
            "Sending...",
            {containerId: 'talent-match-score'}
        );
        b2bServiceObj.SendAssessmentReminder(data.info.id, (status, response) => {
            if (status && response.ok && response.httpStatus === 200) {
                toast.update(updateTeamToast, {
                    render: "Reminder email sent",
                    type: "success",
                    isLoading: false,
                    autoClose: '2000',
                    closeButton: true,
                    hideProgressBar: true
                });
            } else {
                toast.update(updateTeamToast, {
                    render: "Unable to reminder email",
                    type: "error",
                    isLoading: false,
                    autoClose: '2000',
                    closeButton: true,
                    hideProgressBar: true
                });
            }

        })
    }

    useEffect(() => {

        setSalesChartData({
            labels: copy?.salesStrengthsLabels,
            datasets: [
                {
                    label: 'Competence',
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    borderColor: 'rgba(255,99,132,1)',
                    pointBackgroundColor: 'rgba(179,181,198,1)',
                    pointHoverBorderColor: 'rgba(179,181,198,1)',
                    data: data?.sales_strengths?.competence
                },
                {
                    label: 'Attitude',
                    backgroundColor: 'rgba(53, 196, 220, 0.2)',
                    borderColor: 'rgb(53, 196, 220)',
                    pointBackgroundColor: 'rgba(255,99,132,1)',
                    pointHoverBorderColor: 'rgba(255,99,132,1)',
                    data: data?.sales_strengths.attitude
                }
            ]
        })

    }, [data?.sales_strengths, copy?.salesStrengthsLabels])

    useEffect(() => {

        setTeamsChartData({
            labels: copy?.teamStrengthsLabels,
            datasets: [
                {
                    label: 'Team Strengths',
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    borderColor: 'rgba(255,99,132,1)',
                    pointBackgroundColor: 'rgba(255,99,132,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(255,99,132,1)',
                    data: data?.team_strengths
                }
            ]
        })

    }, [data?.team_strengths, copy?.teamStrengthsLabels])


    const [salesChartData, setSalesChartData] = useState({
        labels: copy?.salesStrengthsLabels,
        datasets: [
            {
                label: 'Competence',
                backgroundColor: 'rgba(179,181,198,0.2)',
                borderColor: 'rgba(179,181,198,1)',
                pointBackgroundColor: 'rgba(179,181,198,1)',
                pointHoverBorderColor: 'rgba(179,181,198,1)',
                data: data.sales_strengths.competence
            },
            {
                label: 'Attitude',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                data: data.sales_strengths.attitude
            }
        ]
    });

    const [teamsChartData, setTeamsChartData] = useState({
        labels: copy?.teamStrengthsLabels,
        datasets: [
            {
                label: 'Team Strengths',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                data: data?.team_strengths
            }
        ]
    });

    const [lightOptionsTeams] = useState({
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                },
                position: 'bottom'
            }
        },
        scales: {
            r: {
                min: 0,
                max: 9,
                ticks: {
                    stepSize: 1, // the number of step
                },
                pointLabels: {
                    fonfontSize: 14,
                    color: '#495057',
                    padding: 0,
                    margin: 0
                },
                grid: {
                    color: '#ebedef',
                },
                angleLines: {
                    color: '#ebedef'
                }
            }
        }
    });

    const [lightOptionsSales] = useState({
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                },
                position: 'bottom',
            }
        },
        scales: {
            r: {
                max: 5,
                min: 0,
                stepSize: 1,
                ticks: {
                    stepSize: 1, // the number of step
                },
                pointLabels: {
                    fonfontSize: 14,
                    color: '#495057',
                    padding: 0,
                    margin: 0
                },
                grid: {
                    color: '#ebedef',
                },
                angleLines: {
                    color: '#ebedef'
                }
            }
        }
    });

    const [compatibilityVisible, setCompatibilityVisible] = useState(3);

    const showMoreCompatibility = () => {
        setCompatibilityVisible(data.target_profile_scores.length);

        document.getElementById('showMoreCompatibility').classList.remove('show', 'flex');
        document.getElementById('showMoreCompatibility').classList.add('hide');
        document.getElementById('showLessCompatibility').classList.add('show', 'flex');
    }

    const showLessCompatibility = () => {
        setCompatibilityVisible(3);
        document.getElementById('showLessCompatibility').classList.remove('show', 'flex');
        document.getElementById('showLessCompatibility').classList.add('hide');
        document.getElementById('showMoreCompatibility').classList.add('show', 'flex');
    }

    const getProgressValue = (score) => {
        switch (score) {
            case 9:
                return 100;
            case 8:
                return 90;
            case 7:
                return 80;
            case 6:
                return 70;
            case 5:
                return 55;
            case 4:
                return 45;
            case 3:
                return 35;
            case 2:
                return 25;
            case 1:
                return 10;
            default:
                return 0;
        }
    };

    return (
        <>
            {data &&
            <div className="match-section">
                <ToastContainer containerId="talent-match-score"/>
                {data.target_position_competencies_distribution && data.target_position_competencies_distribution.length > 0 &&
                <div className="white-box">
                    <TargetPositionComposition data={data} matchPercentage={matchPercentage}/>
                </div>}

                <div className="talent-details-strengths-charts">
                    <StrengthsRadarChart
                        title="Team Strengths"
                        data={teamsChartData}
                        options={lightOptionsTeams}
                    />
                    <StrengthsRadarChart
                        title="Sales Strengths"
                        data={salesChartData}
                        options={lightOptionsSales}
                    />
                </div>

                <div className="competences-motivation-section">
                    <CandidateCompetences data={data}/>
                    <CandidateMotivationFactors data={data}/>
                </div>
                <div className="flex-div">
                    {candidateData.skill_assessment.length !== 0 &&
                    <div className="assessments-section" id="boxStyleAssessment">
                        <ToastContainer containerId="candidate-competences"/>
                        <div className="title">
                            <p className='m-0'>
                                {/* {contentfulDate.competenciesSectionTitle} */}
                                Reasoning and Skills
                                <ExplanationTooltip>
                                    {/* {contentfulDate.targetProfileHelpLink} */}
                                </ExplanationTooltip>
                            </p>
                            <p className='xs grey'>Verified by AON online assessments</p>
                        </div>
                        <div className="stats">
                            <Accordion>
                                {candidateData.skill_assessment[0] &&
                                <Accordion.Item eventKey={1} key={1}>
                                    <Accordion.Header>
                                        <div className="accordion-header-wrapper">
                                            <p className="mar-5 right s">English</p>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <div className="scoreline-div">
                                                    <span className="grey-bar">Low</span>
                                                    <span className="grey-bar">Average</span>
                                                    <span className="grey-bar">High</span>
                                                </div>
                                                <ScoreLine
                                                    val={Math.round((candidateData.skill_assessment[0] / 100) * 10)}/>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                </Accordion.Item>
                                }

                                {candidateData.skill_assessment[1] &&
                                <Accordion.Item eventKey={2} key={2}>
                                    <Accordion.Header>
                                        <div className="accordion-header-wrapper">
                                            <p className="mar-5 right s">Verbal Reasoning</p>
                                            <ScoreLine
                                                val={Math.round((candidateData.skill_assessment[1] / 100) * 10)}/>
                                        </div>
                                    </Accordion.Header>
                                </Accordion.Item>
                                }

                                {candidateData.skill_assessment[2] &&

                                <Accordion.Item eventKey={3} key={3}>
                                    <Accordion.Header>
                                        <div className="accordion-header-wrapper">
                                            <p className="mar-5 right s">Logical Reasoning</p>
                                            <ScoreLine
                                                val={Math.round((candidateData.skill_assessment[2] / 100) * 10)}/>
                                        </div>
                                    </Accordion.Header>
                                </Accordion.Item>
                                }


                            </Accordion>
                        </div>
                    </div>
                    }
                    <div className="compability-section" id="boxStyle"
                         style={{
                             flexGrow: candidateData.skill_assessment.length === 0 ? 1 : 0,
                             flexBasis: candidateData.skill_assessment.length !== 0 ? "50%" : "0%",
                         }}>
                        <div className="title">
                            <p className='m-0'>{copy.highestCompatabilitySectionTitle}</p>
                            <p className='xs grey'>Verified by AON online assessments</p>
                        </div>
                        <div className="accordion-main">
                            {data.target_profile_scores.length > 0 ?
                                data.target_profile_scores.sort(function (a, b) {
                                    return b.score - a.score
                                }).slice(0, compatibilityVisible).map((score, index) => (
                                    copy.highestCompatibility.map((scoreCopy) => {
                                        if (score.profile === scoreCopy.code) {
                                            return (
                                                <div className="stat" key={index}>
                                                    <div className="description">
                                                        <p className='title m-0 s'>{scoreCopy.title}</p>
                                                        <p className='score m-0'>
                                                            {score.score === 9 && <span className="bold">100</span>}
                                                            {score.score === 8 && <span className="bold">90</span>}
                                                            {score.score === 7 && <span className="bold">80</span>}
                                                            {score.score === 6 && <span className="bold">70</span>}
                                                            {score.score === 5 && <span className="bold">55</span>}
                                                            {score.score === 4 && <span className="bold">45</span>}
                                                            {score.score === 3 && <span className="bold">35</span>}
                                                            {score.score === 2 && <span className="bold">25</span>}
                                                            {score.score === 1 && <span className="bold">10</span>}
                                                            /100
                                                        </p>
                                                    </div>
                                                    <div className="progress-bar-wrapper">
                                                        <ProgressBar now={getProgressValue(score.score)}/>
                                                    </div>
                                                </div>
                                            )
                                        } else return ''
                                    })
                                ))
                                :
                                <div className="sendToTests">
                                    <p>No compatibility at the moment</p>
                                    <button className="mazhrBtn default-pink" onClick={sendAssessmentReminder}>Send
                                        assessments reminder
                                    </button>
                                </div>
                            }
                        </div>
                        {data.target_profile_scores.length > 0 &&
                        <>
                            <button id="showMoreCompatibility" className='show-more-link link'
                                    onClick={showMoreCompatibility}><p className="xs bold">Show more</p></button>
                            <button id="showLessCompatibility" className='show-more-link link hide'
                                    onClick={showLessCompatibility}><p className="xs bold">Show less</p></button>
                        </>
                        }
                    </div>
                </div>
                {data.assessment_report_list?.length > 0 && <div className="" id="boxStyleReports">
                    <div className="title">
                        <p className='m-0'>{copy.downloadableReportsSectionTitle}</p>
                    </div>
                    <div className="container">
                        {data.assessment_report_list.map((assessment_report, index) => (
                            assessment_report.score_url && copy.assessmentReportNames[assessment_report.assessment_name] ? (
                                <div className="image-container" key={index}>
                                    <a href={assessment_report.score_url} target="_blank" rel="noreferrer">
                                        {/* Add any image or content you want to display */}
                                    </a>
                                    <h2>{copy.assessmentReportNames[assessment_report.assessment_name]}</h2>
                                </div>
                            ) : null
                        ))}
                    </div>
                </div>}
            </div>
            }
        </>
    )
}

export default TalentMatchScore;





