import { useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Button from '../../components/reusableElementsComponents/Button'
import Loading from '../../components/reusableElementsComponents/Loading'
import NewPositionGeneral from '../../components/newOpenPositionComponents/NewPositionGeneral'
import NewPositionAssessments from '../../components/newOpenPositionComponents/NewPositionAssessments'
import TabsMenu from '../../components/reusableElementsComponents/TabsMenu'
import { hasEmptyProperty } from '../../Utilities'
import EmailTemplates from "../../components/openPositionsComponents/EmailTemplates";
import * as Constants from "../../constants"
import B2BApiService from "../../B2bApiService";


function NewOpenPosition () {
    const data = useSelector(state => state.contentfulData.all['new open position']);
    const [formValidate, setFormValidate] = useState(false);
    const [formCompleted, setFormCompleted] = useState(false);
    // let currentUrl = window.location.href;
    // const [visible, setVisible] = useState(false);
    const [validateTextFields, setValidateTextFields] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
        // eslint-disable-next-line
    }, [formCompleted]);



    const backToPositions = () => {
        navigate("/open-positions");
    }
    const activeKey = (key) => { }

    const b2bServiceObj = useMemo(() => {
        return new B2BApiService();
    }
        , []);

    const [generalData] = useState({
        title: "",
        deadline: new Date(),
        description: "",
        recruitment_process: Constants.EXAMPLE_RECRUITMENT_PROCESS,
        industry_id: "",
        target_profile_id: ""
    });

    const [emailData] = useState({
        send_assessment_email: "",
        qualify_email: "",
        reject_email: ""
    });

    const [assessmentData] = useState({
        assessments: [],
        email_list: [],
        is_closed: false
    });

    let template = {
        tabs: [
            {
                component: <NewPositionGeneral data={generalData} formValidate={formValidate} validateTextFields={validateTextFields} />,
                sectionTitle: 'general'
            },
            {
                component: <EmailTemplates data={emailData} />,
                sectionTitle: 'email templates'
            },
            {
                component: <NewPositionAssessments data={assessmentData} />,
                sectionTitle: 'assessments'
            }
        ]
    }

    function handleBeforeUnload (event) {
        if (!formCompleted) {
            event.preventDefault();
            event.returnValue = ""

        }
    }

    // const handleReload = (e) => {
    //     setVisible(false);
    //     e.preventDefault()
    //     window.location.href = currentUrl


    //   }

    // const handleCancel = () => {
    //     setVisible(false);
    //   }

    // const dialogFooter = (
    //     <div style={{display: "flex", justifyContent: "flex-start"}}>
    //       <Button btnstyle={'mazhrBtn default-green white'} icon="pi pi-check" onClick={handleReload} >Yes</Button>
    //       <Button btnstyle={'mazhrBtn default-green white'}  icon="pi pi-times" onClick={handleCancel} >No</Button>
    //     </div>
    //   );








    if (data !== undefined) {
        return (
            <div className="new-open-position">
                {/* <Dialog header="Are you Sure you want to leave?" visible={visible} modal={true} onHide={() => setVisible(false)} footer={dialogFooter}>
                    <p>Changes you made may not be saved.</p>
                </Dialog>; */}
                <div className="exit">
                    <span className="icon icon-exit" onClick={backToPositions}>&nbsp;</span>
                </div>
                <p className='bold m section title'>Create New Open Position</p>
                <TabsMenu template={template} bottomNav center activeKey={activeKey} />
                <Button btnstyle={'mazhrBtn default-green tall'} type='button' onClick={onSubmit}>
                    Post new open position
                </Button>
            </div>
        )
    } else {
        return <Loading />
    }



    function onSubmit () {

        setValidateTextFields(true);
        let empty = hasEmptyProperty(generalData, ['title', 'deadline', 'industry_id', 'target_profile_id'])
        if (empty !== false) {
            /**
             * @todo : Show error thing here
             */
            setFormValidate(true);
            return;
        }


        let tempGeneralData = generalData;

        tempGeneralData.deadline = new Date(tempGeneralData.deadline)

        let deadlineDateString = tempGeneralData.deadline.getFullYear() + "/";

        deadlineDateString += ("0" + (tempGeneralData.deadline.getMonth() + 1)).slice(-2) + "/";
        deadlineDateString += ("0" + tempGeneralData.deadline.getDate()).slice(-2);

        deadlineDateString += " " + ("0" + tempGeneralData.deadline.getHours()).slice(-2) + ":"
        deadlineDateString += ("0" + tempGeneralData.deadline.getMinutes()).slice(-2) + ":";

        deadlineDateString += ("0" + tempGeneralData.deadline.getSeconds()).slice(-2);

        tempGeneralData.deadline = deadlineDateString;

        let tempAssessmentData = assessmentData

        setFormCompleted(true);

        if (Array.isArray(tempAssessmentData?.email_list)) {
            tempAssessmentData.email_list = tempAssessmentData?.email_list?.join(',')
        }

        b2bServiceObj.SavePool(JSON.stringify({ ...tempGeneralData, ...emailData, ...tempAssessmentData }), (response) => {
            if (response) {
                navigate("/open-positions/" + response.hash);
            }
        })
    }
}

export default NewOpenPosition

