import { useState, useEffect, memo } from "react";
import Tables from "@mazhroy/mazhr-data-tables";
import loading from '../../../assets/images/icons/loading.gif';
import B2BApi from '../../../B2bApiService';
import { Select } from "../../formComponents/Inputs";
import * as Constants from "../../../constants"
import TalentDetails from "../../talentsComponents/TalentDetails";
import { Dialog } from 'primereact/dialog';
import {useSelector} from "react-redux";
const MotivationStrenghts = (props) => {
    const definitions = useSelector(state => state.contentfulAnalyticsData?.all['motivations']);
    const scaleDescription = useSelector(state => state.contentfulAnalyticsData?.all['motivations']?.scaleDescription);
    const [dataTableList, setDataTableList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [chartDialog, setChartDialog] = useState(false);
    const [showChartBtn, setShowChartBtn] = useState(false);
    const [allCol, setAllCol] = useState([]);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [columns, setColumns] = useState([]);
    const [rowData, setRowData] = useState();
    const [userDataDialog, setUserDataDialog] = useState(false);
    const {downloadDataUpdation, columnLabelUpdation} = props;
    let b2bObj = new B2BApi();

    useEffect(() => {
        if (props.tabActiveKey === Constants.TAB_MOTIVATIONS_KEY)
            getMotivationStrength();
        // eslint-disable-next-line
    }, [props.tabActiveKey, props.selectedTeams])

    useEffect(()=>
    {
       if(userDataDialog === false) {
           setUserDataDialog(true)
       }
    // eslint-disable-next-line
    },[rowData])

    useEffect(()=> {
        if(showChartBtn === false) {
            if(downloadDataUpdation) {
                downloadDataUpdation(dataTableList)
            }
        }
        // eslint-disable-next-line
    },[showChartBtn])

    useEffect(()=> {
        if(downloadDataUpdation) {
            downloadDataUpdation(dataTableList)
        }
        // eslint-disable-next-line
    }, [dataTableList])

    useEffect(() => {
        if (allCol.length && selectedOption === "") {
            const val = "All"
            setSelectedOption(val)
            let newCol = allCol.find(x => x.group_name === val).columns_list;
            setColumns([...newCol]);
            if(columnLabelUpdation) {
                columnLabelUpdation(newCol)
            }
        }
        // eslint-disable-next-line
    }, [allCol, selectedOption])

    const getMotivationStrength = () => {
        let hashes = props?.selectedTeams?.map(x => x.hash) || [];
        b2bObj.GetMotivationStrengthList(
            {
                company_id: "",
                hash_list: hashes,
                is_user_pool: props.is_pool_list
            }, definitions, setDataTableList, setAllCol, setLoader, setSelectOptions);
    }

    const handleChange = (e) => {
        const val = e.target.value;
        let newCol = allCol.find(x => x.group_name === val).columns_list;

        setColumns([...newCol]);
    }

    const selectedRow = (data) => {
        if (data) {
            if(downloadDataUpdation) {
                downloadDataUpdation(dataTableList)
            }
            setShowChartBtn(true);
        } else {
            setShowChartBtn(false);
        }
    }
    const onHide = () => {
        setUserDataDialog(false)
        setRowData()
    }

    const onChartHide = () => {
        setChartDialog(false);
    }

    return (
        <>
            {loader && !dataTableList ?
                <div className='page-loading'>
                    <img src={loading} alt="Loading..." />
                </div> :
                <div className="main-div">
                    <Select
                        label='Groups'
                        options={selectOptions}
                        handleChange={handleChange}
                        errorContent={"Please select group"}
                    />
                    <div className="dropdowns">
                        {showChartBtn &&
                            <button className="mazhrBtn default-green" onClick={() => setChartDialog(true)}> Show Chart</button>
                        }
                    </div>
                    {dataTableList && 
                    <>
                    {rowData &&          
                    <Dialog style={{width: "1000px"}} visible={userDataDialog} onHide={onHide}>
                        <TalentDetails
                        candidateId={rowData?.user_id}
                        asEmployee={!props.is_pool_list}
                        />
                    </Dialog>}
                    <Tables  tabActiveKey={props.tabActiveKey} setRowData={setRowData} dataTableList={dataTableList} chartDialog={chartDialog} selectedRow={selectedRow} onChartHide={onChartHide} allCol={columns} scaleDescription={scaleDescription}/>
                    </>
                    }
                </div>
            }
        </>
    )
}
export default memo(MotivationStrenghts);
