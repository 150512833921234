import location from '../../assets/images/icons/location.svg';
import WorkExperience from '../../assets/images/icons/workExperience.svg'
function TalentWorkHistory(candidateData) {
  const data = candidateData?.candidateData?.work_history;

  return (
      <div className="experience-wrapper">
        <div className="experience section">
          {data?.length !==0?
          data?.map((work, index) => {
            return (
                <div key={index} className="experience-card row w-100 mb-4 ms-0">
                  <div className="d-none d-lg-block col-2">
                    {work.start_date &&
                    <p className="position-relative">
                      <span className="dot green"></span>
                      <span className="ms-2 light s">{work.start_date}</span>
                    </p>
                    }
                    {work.end_date &&
                    <p className="position-relative">
                      <span className="dot purple"></span>
                      <span className="ms-2 light s">{work.end_date}</span>
                    </p>
                    }
                  </div>

                  <div className="col-12 col-lg-10">
                    <div className="title d-flex justify-content-between flex-column flex-lg-row">
                      <div className="w-100 w-lg-50">
                        {work.title && <p className="semi-bold m-0">{work.title}</p>}
                        {work.company && <p className="light s">{work.company}</p>}

                        {work.industry &&
                        <p className="light s mt-2">
                          <span className="text-uppercase me-4">Industry</span>
                          <span className="industry">
                            {work.industry === '1' && 'Wholesale and retail'}
                            {work.industry === '2' && 'Manufacturing and mining'}
                            {work.industry === '3' && 'Transport and storage'}
                            {work.industry === '4' && 'Accomodation and restaurants'}
                            {work.industry === '5' && 'Construction'}
                            {work.industry === '6' && 'Water and waste management'}
                            {work.industry === '7' && 'Electricity, gas, heat and refrigeration maintenance'}
                            {work.industry === '8' && 'Maintenance and repair'}
                            {work.industry === '9' && 'Real estate'}
                            {work.industry === '10' && 'Financial services and insurance'}
                            {work.industry === '11' && 'Marketing, communication and publication'}
                            {work.industry === '12' && 'Consulting, planning and research'}
                            {work.industry === '13' && 'Administrative and support services'}
                            {work.industry === '14' && 'Legal and accounting services'}
                            {work.industry === '15' && 'ICT, software, games'}
                            {work.industry === '16' && 'National defence and protection functions'}
                            {work.industry === '17' && 'Education and training'}
                            {work.industry === '18' && 'Health and social work'}
                            {work.industry === '19' && 'Entertainment, art, recreation'}
                            {work.industry === '20' && 'Agriculture, forestry, fisheries'}
                            {work.industry === '21' && 'Household work'}
                          </span>
                        </p>
                        }
                      </div>

                      {work.start_date || work.end_date ?
                          <div className="d-lg-none">
                            <p className="m-0 light s">
                              {work.start_date && <span>from {work.start_date}</span>}
                              {work.end_date && <span> until {work.end_date}</span>}
                            </p>
                          </div>
                          :
                          ''
                      }

                      {work.location &&
                      <div className="d-flex align-items-start">
                        <p className="me-2 light s"> {work.location}</p>
                        <img src={location} alt="location" />
                      </div>
                      }


                    </div>
                    {work.keywords && <p className="light s">{work.keywords}</p>}
                  </div>
                </div>
            )
          }):
          <div className='add-work-history'>
            <img height="28px" className='icon-svg' src={WorkExperience} alt="send email" />
            <span>Add Work Experience</span>
          </div>
          }
        </div>
      </div>
  )
}

export default TalentWorkHistory


