
import { useState, useEffect, memo } from "react";
import Tables from "@mazhroy/mazhr-sales-report";
import './salesReport.scss';
// import { SalesSelect } from "../../formComponents/Inputs";
import TalentDetails from "../../talentsComponents/TalentDetails";
import { Dialog } from 'primereact/dialog';
import B2BApiService from "../../../B2bApiService";
import loading from '../../../assets/images/icons/loading.gif';
import * as Constants from "../../../constants"
import {useSelector} from "react-redux";
const SalesReport = (props) => {
   const b2bApiService = new B2BApiService();
    const contentfulData = useSelector(state => state.contentfulAnalyticsData?.all['salesStrengths' || 'sales strengths']);
    const scaleDescription = useSelector(state => state.contentfulAnalyticsData?.all['salesStrengths' || 'sales strengths']?.scaleDescription);
    const {downloadDataUpdation, columnLabelUpdation} = props
    let [tableData, setTableData] = useState([]);
    const [tableColoumns, setTableColoumns] = useState([]);
    let [colList, setcolList] = useState([]);
    const [showChartBtn, setShowChartBtn] = useState(false);
    const [chartDialog, setChartDialog] = useState(false);
    const [selectedFilterCol, setSelectedFilterCol] = useState('Competency');
    const [loader, setLoader] = useState(true);
    const [rowData, setRowData] = useState();
    const [userDataDialog, setUserDataDialog] = useState(false);

    useEffect(() => {
        if (props.tabActiveKey === Constants.TAB_SALES_STRENGTHS_KEY) {
            getSalesDataFunction();
            // columnLabelUpdation(allCol)
            if(columnLabelUpdation) {
                columnLabelUpdation([...tableColoumns])
            }
            
        }
        // eslint-disable-next-line
    }, [props.tabActiveKey, props.selectedTeams]);

    useEffect(()=> {
        if(userDataDialog === false) {
            setUserDataDialog(true)
        }
    // eslint-disable-next-line
    },[rowData])


    useEffect(()=> {
        if(columnLabelUpdation) {
            columnLabelUpdation([...tableColoumns])
        }
        // eslint-disable-next-line
    },[tableColoumns])

    useEffect(()=> {
        if(downloadDataUpdation) {
            downloadDataUpdation([...tableData])
        }
        // eslint-disable-next-line
    },[tableData])


    const getSalesDataFunction = () => {
        let hash_list = [];
        if (props.selectedTeams.length > 0) {
            hash_list = props.selectedTeams.map(x => x.hash);
        }
        b2bApiService.getSalesData(
            {
                hash_list: hash_list,
                is_pool_list: props.is_pool_list 
                
            }, setTableColoumns, setTableData, setcolList,contentfulData, setLoader);
    }

    const selectedRow = (data) => {
        if (data) {
            if (data.length > 1) {
                const index = colList.findIndex(val => val.id === 'comp&attitude');
                if (index !== -1) {
                    index && colList.splice(index, 1);
                    setcolList([...colList]);
                }
            } else {
                const lastEle = colList.at(-1);
                if (lastEle.id !== 'comp&attitude') {
                    colList.push({ id: 'comp&attitude', title: 'Competency & Attitude' });
                    setcolList([...colList]);
                }
            }
            if(downloadDataUpdation) {
                downloadDataUpdation(data)
            }
            setShowChartBtn(true);
        } else {
            setShowChartBtn(false);
            if(downloadDataUpdation) {
                downloadDataUpdation(tableData)
            }
           
        }
    }

    const onChartHide = () => {
        setSelectedFilterCol('Competency')
        setChartDialog(false);

    }

    const OnSelectColumn = (e) => {
        const val1 = e.target.value;
        setSelectedFilterCol(val1)
        setChartDialog(true);
    }

    const onHide = () => {
        setUserDataDialog(false)
        setRowData()
    }

    return (
        <>
            {loader ?
                <div className='page-loading'>
                    <img src={loading} alt="Loading..." />
                </div> :

                <div className="main-div">
                    <div className="dropdowns">


                        {/* {colList.length > 0 && showChartBtn &&
                            <SalesSelect
                                id={'coloumns'}
                                label='Select column'
                                options={colList}
                                handleChange={OnSelectColumn}
                                option={selectedFilterCol}
                            />
                        } */}
                        {colList.length > 0 && showChartBtn &&
                            <button className="mazhrBtn default-green" onClick={() => setChartDialog(true)}> Show Chart</button>
                        }

                    </div>
                    <>
                    {rowData &&          
                    <Dialog style={{width: "1000px"}} visible={userDataDialog} onHide={onHide}>
                        <TalentDetails
                        candidateId={rowData?.user_id}
                        asEmployee={!props.is_pool_list}
                        />
                    </Dialog>}
                    <Tables selectedFilterCol={OnSelectColumn}  tabActiveKey={props.tabActiveKey} setRowData={setRowData} OnSelectColumn={OnSelectColumn} colList={colList} dataTableList={tableData} tableColoumns={tableColoumns} selectedRow={selectedRow} chartDialog={chartDialog} selectedCol={selectedFilterCol} onChartHide={onChartHide} scaleDescription={scaleDescription} />
                    </>

                   

                </div>
            }
        </>
    )
}

export default memo(SalesReport)


