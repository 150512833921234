import { useState,useEffect } from 'react';
import { EditorState,ContentState, convertFromHTML } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import { stateToHTML } from "draft-js-export-html"
import ExplanationTooltip from "../../components/reusableElementsComponents/ExplanationTooltip";


function EmailTemplates ({data,handleData}) {

    let [newPositionFormData, setEmailData] = useState(data);
    const [sendToAssessmentsEmail, setSendToAssessmentsEmail] = useState('');
    const [rejectEmail, setRejectEmail] = useState('');
    const [qualifyEmail, setQualifyEmail] = useState('');

    function handleChange (value, key) {
        // newPositionFormData[key] = value;
        setEmailData({ ...newPositionFormData, [key]: value });
        handleData(newPositionFormData);
    }

    useEffect(()=>{
        setSendToAssessmentsEmail(EditorState.createWithContent(ContentState.createFromBlockArray(
            convertFromHTML(`<p>${data.send_assessment_email}</p>`))));

        setRejectEmail(EditorState.createWithContent( ContentState.createFromBlockArray(
                convertFromHTML(`<p>${data.reject_email}</p>`))));

        setQualifyEmail(EditorState.createWithContent(ContentState.createFromBlockArray(
                    convertFromHTML(`<p>${data.qualify_email}</p>`))));
    },[data])

    return (
        <div className="email-templates section">
            <p className='m bold'>
                Email Templates
                <ExplanationTooltip>
                    <p>From here you can send email invitations to people considering assessments or information on how the process continues.</p>
                </ExplanationTooltip>
            </p>
            <div className="email-editor">
                <p className="sub-title grey regular">Send to assessments</p>
                <Editor
                    editorState={sendToAssessmentsEmail}
                    toolbarClassName="editor-toolbar"
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor"
                    onEditorStateChange={setSendToAssessmentsEmail}
                    placeholder='Start typing...'
                    onChange={e => {
                        handleChange(stateToHTML(sendToAssessmentsEmail.getCurrentContent()), 'send_assessment_email');
                    }}
                />
            </div>
            <div className="email-editor">
                <p className="sub-title grey regular">Reject</p>
                <Editor
                    editorState={rejectEmail}
                    toolbarClassName="editor-toolbar"
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor"
                    onEditorStateChange={setRejectEmail}
                    placeholder='Start typing...'
                    onChange={e => {
                        handleChange(stateToHTML(rejectEmail.getCurrentContent()), 'qualify_email');
                    }}
                />
            </div>
            <div className="email-editor">
                <p className="sub-title grey regular">
                    Qualify
                    <ExplanationTooltip>
                        <p>This is how you know if person qualifies</p>
                    </ExplanationTooltip>
                </p>
                <Editor
                    editorState={qualifyEmail}
                    toolbarClassName="editor-toolbar"
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor"
                    onEditorStateChange={setQualifyEmail}
                    placeholder='Start typing...'
                    onChange={e => {
                        handleChange(stateToHTML(qualifyEmail.getCurrentContent()), 'reject_email');
                    }}
                />
            </div>
        </div>
    )
}

export default EmailTemplates
