import location from '../../assets/images/icons/location.svg';
import Education from '../../assets/images/icons/education.svg';
function TalentEducation(candidateData) {
  const data = candidateData?.candidateData?.education;

  return (
      <div className="experience-wrapper">
        <div className="experience section">
          {data?.length !==0?
          data?.map((education, index) => {
            return (
                <div key={index} className="experience-card row w-200 mb-4 ms-0">
                  <div className="d-none d-lg-block col-2">
                    {education.start_date &&
                    <p className="position-relative">
                      <span className="dot green"></span>
                      <span className="ms-2 light s">{education.start_date}</span>
                    </p>
                    }
                    {education.end_date &&
                    <p className="position-relative">
                      <span className="dot purple"></span>
                      <span className="ms-2 light s">{education.end_date}</span>
                    </p>
                    }
                  </div>

                  <div className="col-12 col-lg-10">
                    <div className="title d-flex justify-content-between flex-column flex-lg-row">
                      <div className="w-100 w-lg-50">
                        {education.school && <p className="semi-bold m-0">{education.school}</p>}
                        {education.degree && <p className="light s">{education.degree}</p>}
                      </div>

                      {education.start_date || education.end_date ?
                          <div className="d-lg-none">
                            <p className="m-0 light s">
                              {education.start_date && <span>from {education.start_date}</span>}
                              {education.end_date && <span> until {education.end_date}</span>}
                            </p>
                          </div>
                          :
                          ''
                      }

                      {education.location &&
                      <div className="d-flex align-items-start">
                        <p className="me-2 light s"> {education.location}</p>
                        <img src={location} alt="location" />
                      </div>
                      }
                    </div>
                    {education.keywords && <p className="light s">{education.keywords}</p>}
                  </div>
                </div>
            )
          }):
          <div className='add-work-history'>
          <img height="20px" className='icon-svg' src={Education} alt='work History Icon' />
          <span>Add Educational Background</span>
        </div>
          }
        </div>
      </div>
  )
}
export default TalentEducation
